.main-nav {
  z-index: 1;
  background-color: #fff;
  border-radius: 0;
  grid-area: 1 / 1 / 1 / 7;
  grid-template-columns: repeat(6, auto);
  margin-left: 0;
  margin-right: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: top 2s;
  display: none;
  transform: translate3d(0, 0, 2px);
}

@media only screen and (width <= 568px) and (-webkit-device-pixel-ratio: 2) {
  .main-nav {
    margin-left: 0;
    margin-right: 0;
  }
}

.navActive {
  display: grid;
}

.nav--btn {
  cursor: pointer;
  text-align: center;
  border: none;
  margin: auto;
  transition: all .5s;
  display: block;
}

.nav--btn:hover, .nav--btn a:hover {
  opacity: .8;
  color: #1937b9;
  font-weight: bold;
  transition: all .5s;
  transform: scale(1.1);
}

.smartMenu {
  z-index: 2;
  display: grid;
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.sm {
  z-index: 2;
  cursor: pointer;
  opacity: .7;
  background-color: #2222221a;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  box-shadow: 0 0 #000;
}

@media screen and (width <= 428px) {
  .sm {
    margin-bottom: 0;
  }

  .smartMenu {
    gap: 15px;
    display: grid;
    bottom: 30px;
    right: 20px;
  }
}

.sm:hover {
  transition: all .5s;
  transform: scale(1.05);
}

.smartMenu--back {
  grid-row: 3 / 4;
}

.smartMenu--inactive {
  display: none;
}

.btnactive, .btnactive a {
  color: #1937b9;
  font-weight: bold;
}

.subsection--cars {
  margin-bottom: gap 30px;
  grid-template-columns: auto;
  max-height: 496px;
  display: grid;
}

.btntxt {
  background: none;
  font-size: 15px;
}

.btndot {
  opacity: .2;
  cursor: pointer;
  background-color: #3e3e3e;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  transition: all .5s;
}

.btndot:hover {
  opacity: .6;
}

/*# sourceMappingURL=index.7c27a2a6.css.map */
